.about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: aliceblue;
}

.about-content {
  max-width: 900px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  color: #333;
}

.about-content h2 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid navy;
  padding-bottom: 10px;
}

.about-content h3 {
  font-size: 1.8rem;
  margin-top: 20px;
  color: navy;
}

.about-content h4 {
  font-size: 1.5rem;
  margin-top: 15px;
  color: #333;
}

.about-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #555;
}

.about-content ul {
  list-style: none; /* Remove default bullet points */
  padding: 0;
  margin-left: 20px;
}

.about-content ul li {
  font-size: 1.1rem;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}

.about-content ul li::before {
  content: '\2022'; /* Unicode for bullet point */
  font-size: 1.5rem;
  color: navy; /* Navy color */
  position: absolute;
  left: 0;
  top: 0;
}
