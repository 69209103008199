/* Home container */
.home-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  background-color: aliceblue; /* Match background color with other components */
  color: #333;
}

/* Home container */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  background-color: aliceblue; /* Match background color with other components */
  color: #333;
}

/* Home content */
.home-content {
  text-align: center;
  z-index: 1;
  margin-top: 200px;
  
}

.home-content h1 {
  font-size: 2.5rem; /* Adjusted font size */
  margin-bottom: 20px;
  color: #333;
}

.home-content p {
  font-size: 1.3rem;
  color: #555;
  margin-bottom: 20px;
}

/* About button */
.home-content .about-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #ff6347;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.home-content .about-button:hover {
  background-color: #e55337;
  transform: translateY(-3px);
}

/* Adding a subtle background overlay */
.home-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: 0;
}

/* Button style */
.button-74 {
  background-color: navy;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: 4px 4px 0 0 black;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  margin-bottom: 30px;
  transition: transform 0.5s ease;
}

.button-74:hover {
  transform: translateY(-5px);
}

.button-74:active {
  box-shadow: 2px 2px 0 0 #422800;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-74 {
      min-width: 120px;
      padding: 0 25px;
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .home-content h1 {
      font-size: 2rem;
  }

  .home-content p {
      font-size: 1.2rem;
  }

  .home-content .about-button {
      padding: 8px 16px;
      font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .home-content h1 {
      font-size: 1.5rem;
  }

  .home-content p {
      font-size: 1.1rem;
  }

  .home-content .about-button {
      padding: 6px 14px;
      font-size: 0.8rem;
  }
}
