.privacy-policy-container {
    padding: 40px;
    background-color: aliceblue;
  }
  
  .privacy-policy-content {
    max-width: 900px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-policy-content h2 {
    font-size: 2.2em;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid navy;
    padding-bottom: 10px;
  }
  
  .privacy-policy-content p {
    font-size: 1.1em;
    line-height: 1.8;
    color: #555;
    margin-bottom: 20px;
  }
  
  .privacy-policy-content h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: navy;
  }
  
  .privacy-policy-content a {
    color: #2980b9;
    text-decoration: none;
  }
  
  .privacy-policy-content a:hover {
    text-decoration: underline;
  }

  .social-links {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between icons */
    margin-top: 30px;
  }
  
  .social-links a {
    font-size: 2rem; /* Adjust the size as needed */
    color: navy; /* Adjust the color as needed */
    text-decoration: none;
  }
  
  .social-links a:hover {
    color: #2980b9; /* Adjust the hover color as needed */
  }
  
  