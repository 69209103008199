.why-us-container {
    padding: 40px;
    background-color: aliceblue;
  }
  
  .why-us-content {
    max-width: 900px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .why-us-content h2 {
    font-size: 2.2em;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid navy;
    padding-bottom: 10px;
  }
  
  .why-us-list {
    list-style-type: none;
    padding: 0;
  }
  
  .why-us-list li {
    margin-bottom: 20px;
  }
  
  .why-us-list h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: navy;
  }
  
  .why-us-list p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin-bottom: 10px;
  }
  