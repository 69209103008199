.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: aliceblue;
  }
  
  .footer-content {
    max-width: 900px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    color: #333;
  }
  

  
  .footer-social-links {
    display: flex;
    justify-content: center;
    gap: 100px; /* Adjusts spacing between icons */
    margin-top: 20px;
  }
  
  .footer-social-links a {
    font-size: 2rem;
    color: navy;
    text-decoration: none;
    margin-left: 30px; /* Adds margin to the left side of each link */
  }
  
  .footer-social-links a:first-child {
    margin-left: 0; /* Removes margin for the first icon */
  }
  
  .footer-social-links a:hover {
    color: #555; /* Change hover color if needed */
  }
  